<template>
  <div v-if="type === def">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="card">
      <div class="title">
        <div class="caption">{{ $t('confirm_code_to_continue') }}</div>
      </div>
      <div class="content">
        <div v-html="$t('email.verify.content', { email: email })" style="color: #686E74"></div>
        <div class="verificationBox mt-4">
          <div class="hint">
            <div style="display: inline-block">{{ $t('enter_code') }}</div>
            <div class="resend-box" @click="resend">
              <span :style="timer.sec ? 'color: #CCC' : 'color: #686E74'">{{ $t('email.verify.resend') }}</span>
              <span v-if="timer.sec">({{ timer.sec }}s)</span>
            </div>
          </div>
          <div class="code">
            <CodeComponent ref="child" :attempt="attempt" :limit="limit" @auth="authEvent" @status="statusEvent" />
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="!status" class="btn btn-solid btn-blue" @click="auth">{{ $t('auth') }}</button>
        </div>
        <div class="mt-4">
          <div class="link link-info">
            <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { codeSend, auth } from '../../api/2fa'
import CodeComponent from '@/components/CodeComponent.vue'
import lib from '@/lib'

export default {
  name: 'email.update.verify.component',
  props: {
    email: String,
    def: String,
    routerName: String
  },
  components: {
    CodeComponent
  },
  data () {
    return {
      status: false,
      loading: false,
      type: 'email',
      timer: {
        status: false,
        interval: null,
        current: new Date(),
        target: null,
        sec: 0
      },
      attempt: 0,
      limit: 0
    }
  },
  async created () {
    if (this.type !== this.def) {
      return false
    }
    this.loading = true
    lib.setTimer(this.timer)
    if (this.timer.status) {
      await this.mailSend()
    }
    this.loading = false
  },
  methods: {
    async authEvent () {
      await this.auth()
    },
    statusEvent (status) {
      this.status = status
    },
    async resend () {
      lib.setTimer(this.timer)
      if (this.timer.status) {
        await codeSend(this.type)
      }
    },
    async mailSend () {
      const ret = await codeSend(this.type)
      if (!ret.status) {
        alert('mail error')
        clearInterval(this.timer.interval)
        localStorage.removeItem('targetTime')
        this.timer.status = true
      }
    },
    async auth () {
      this.status = false
      const code = this.$refs.child.getCode()
      const ret = await auth(this.type, { code: code })
      if (!ret.status) {
        this.attempt = ret.data.attempt
        this.limit = ret.data.limit
        if (this.limit === this.attempt) {
          this.$router.push({
            name: 'customer.unused'
          })
          return false
        }
        this.$bvModal.show('code-modal')
        this.status = true
        return false
      }
      localStorage.removeItem('targetTime')
      this.$router.push({
        name: this.routerName,
        params: {
          mode: true
        }
      })
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div>
        <EmailSettingVerify :email="email" :def="def" :routerName="$route.params.routerName" />
        <GoogleSettingVerify :def="def" :routerName="$route.params.routerName" />
        <SmsSettingVerify :mobile="mobile" :def="def" :routerName="$route.params.routerName" />
      </div>
    </div>
  </div>
</template>

<script>
import { bindInfo, info } from '../api/2fa'
import EmailSettingVerify from '@/components/email/EmailSettingVerify.vue'
import GoogleSettingVerify from '@/components/google/GoogleSettingVerify.vue'
import SmsSettingVerify from '@/components/sms/SmsSettingVerify.vue'

export default {
  name: 'setting.verify',
  data () {
    return {
      loading: false,
      email: '',
      def: '',
      mobile: {
        code: '',
        number: ''
      }
    }
  },
  components: {
    EmailSettingVerify,
    GoogleSettingVerify,
    SmsSettingVerify
  },
  created () {
    if (this.$route.params.routerName === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
      return false
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retBindInfo = await bindInfo()
      for (const item in retBindInfo.data.binding) {
        if (retBindInfo.data.binding[item] === 1) {
          this.def = item.toLowerCase()
          break
        }
      }
      const retInfo = await info()
      this.email = retInfo.data.customer.email
      this.mobile = retInfo.data.customer.mobile
      this.loading = false
    }
  }
}
</script>
